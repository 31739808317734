<template>
  <div class="my-ads">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-12 col-lg-10 col-xl-10 col-12 pad-top-25">
        <div class="row margin-0 margin-bottom-15">
          <div class="col pad-0 pad-top-5">
            <h4 class="ad-type"><ion-icon name="reader-outline"></ion-icon> My Ads</h4>
          </div>
          <div class="col pad-0 text-right">
            <button class="btn btn-light btn-sm bold" @click="getProducts()"><ion-icon name="refresh-outline"></ion-icon></button>
          </div>
        </div>
        <div class="row margin-0 list-head">
          <div class="col-md-4 col-12">
            <h5>Ad Title</h5>
          </div>
          <div class="col-md-3 col-12 hidden-xs">
            <h5>Category &amp; Price</h5>
          </div>
          <div class="col-md-3 col-12 hidden-xs">
            <h5>Stats</h5>
          </div>
          <div class="col hidden-xs">
            <h5>Status</h5>
          </div>
        </div>
        <template v-if="products && products.results.length>0">
          <div v-for="item in products.results" :key="item.id" class="row margin-0 list-body">
            <div class="col-md-4 col-12">
              <div class="visible-xs">
                <span class="status-block f-right active" v-if="item.status==='ACTIVE'">ACTIVE</span>
                <span class="status-block f-right inactive" v-else-if="item.status==='INACTIVE'">INACTIVE</span>
                <span class="status-block f-right soldout" v-else-if="item.status==='SOLDOUT'">MARKED AS SOLD OUT</span>
                <span class="status-block f-right soldout" style="text-transform:uppercase;" v-else>{{item.status}}</span>
              </div>
              <div class="product-img">
                <img :src="item.thumbnail?item.thumbnail:'/noimage.jpg'" class="img-fluid" alt="" srcset="">
              </div>
              <p class="margin-top-5 margin-bottom-0 visible-xs"><span class="fa fa-inr"></span> {{parseFloat(item.price).toLocaleString()}}</p>
              <h4><b>{{item.title}}</b></h4>
            </div>
            <div class="col-md-3 col-12">
              <p class="margin-bottom-5 hidden-xs"><span class="fa fa-inr"></span> {{parseFloat(item.price).toLocaleString()}}</p>
              <p class="margin-bottom-5 cat-label">{{item.category}} >> {{item.sub_category}}</p>
              <p class="margin-bottom-5 cat-label">Posted On: <b>{{new Date(item.created_on).toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' })}}</b></p>
              <button @click="updateProduct(item, 'VERIFICATION')" class="btn btn-sm btn-outline-warning margin-5">Make Under Verification</button>
            </div>
            <div class="col-md-3 col-12">
              <p class="margin-bottom-5 cat-label"><span class="fa fa-eye"></span> Views: <b>{{item.views || 0}}</b></p>
              <button v-if="!item.featured" @click="updateFeatured(item, true)" class="btn btn-sm btn-outline-secondary">Mark as Featured</button>
              <button v-if="item.featured" @click="updateFeatured(item, false)" class="btn btn-sm btn-outline-secondary">Mark as end Featured</button>
              <br><span v-if="item.featured" class="badge badge-success pad-5">Featured Ad</span>
            </div>
            <div class="col-md-2 col-12 pad-left-0">
              <div class="hidden-xs" style="display: block;">
                <span class="status-block active" v-if="item.status==='ACTIVE'">ACTIVE</span>
                <span class="status-block inactive" v-else-if="item.status==='INACTIVE'">INACTIVE</span>
                <span class="status-block soldout" v-else-if="item.status==='SOLDOUT'">MARKED AS SOLD OUT</span>
                <span class="status-block soldout" style="text-transform:uppercase;" v-else>{{item.status}}</span>
              </div>
              <div class="pad-top-10">
                <button @click="$router.push(`/my-listing/${item.id}/`)" class="btn btn-sm btn-outline-secondary margin-5"><span class="fa fa-pencil"></span> Edit Ad</button>
                <template v-if="item.status==='ACTIVE'">
                  <button @click="updateProduct(item, 'SOLDOUT')" class="btn btn-sm btn-outline-success margin-5"><span class="fa fa-check"></span> Mark as Soldout</button>
                </template>
                <template v-else>
                  <button @click="updateProduct(item, 'ACTIVE')" class="btn btn-sm btn-outline-primary margin-5">Make Active</button>
                </template>
                <button @click="updateProduct(item, 'BLOCKED')" class="btn btn-sm btn-outline-danger margin-5">Mark Blocked</button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row margin-0">
            <div class="col-12 text-center pad-30">
              <button class="btn btn-success btn-sm margin-bottom-5" @click="$router.push('/post-free-ad/')">+ Post free Ad</button><br>
              <span class="txt-grey">No Ads added till now</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            products: null,
            loading: true
        }
    },
    created() {
        this.getProducts();
    },
    methods: {
        getProducts() {
          this.loading = true;
          this.$cs.myproduct.list({
            params: {
              sort: '-created_on'
            }
          })
          .then(res => {
            this.products = res.data;
            this.loading = false;
          })
        },
        updateProduct(item, status) {
          item.status = status;
          this.$cs.myproduct.update({
            resource: `${this.$cs.myproduct.api}${item.id}/`,
            data: item
          })
          .then(res => {
            this.getProducts();
          })
        },
        updateFeatured(item, status) {
          item.featured = status;
          this.$cs.myproduct.update({
            resource: `${this.$cs.myproduct.api}${item.id}/`,
            data: item
          })
          .then(res => {
            this.getProducts();
          })
        },
    }
}
</script>
<style lang="stylus" scoped>
.product-img
  width 10vh
  height 10vh
  border 1px solid #ddd
  display flex
  justify-content center
  align-items center
  border-radius 2px
  overflow hidden
  img
    max-height 100%
.status-block
  padding 3px 10px
  border 1px solid #ddd
  border-radius 10px
  font-size 0.7em
  font-weight bold
  margin 5px
  letter-spacing 0.2px
  background-color #aaaaaa
  color #fff
  &.active
    background-color green
  &.soldout
    background-color orange
.my-ads
  min-height 80vh
  background-color #ffffff
  .ad-type
    color #333333
    letter-spacing 0.3px
  .list-head
    background-color #eeeeee
    border-radius 4px
    div[class^="col"], div[class*=" col"]
        padding 15px
        h5
          margin 0px
          font-weight bold
  .list-body
    border 1px solid #eee
    div[class^="col"], div[class*=" col"]
      padding 10px
      h4
        margin 7px 0px 10px
        font-size 0.95em
        font-weight 500
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
    .price
      color green
      font-weight 600
    .cat-label
      font-size 0.8em
      color #666666
@media (max-width: 767px)
  .my-ads
    min-height 80vh
    .list-body
      border 1px solid #dddddd
      margin 10px 0px
      padding 5px 0px
      border-radius 4px
      div[class^="col"], div[class*=" col"]
        padding 5px 10px 0px
      .b-top
        border-top 1px solid #eee
</style>
